








































import AutoComplete from './AutoComplete.vue';
import { customerModule } from '@/store/modules/customer';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import DocumentMixin from '@/mixins/document';
import { Customer } from '../interfaces/customer';

@Component({
    components: { AutoComplete }
})
export default class AutoCompleteCustomer extends Mixins(DocumentMixin) {
    @Prop({ default: 0 }) type!: number;
    @Prop() value!: string;
    @Prop({ type: String }) readonly size!: string;
    @Prop({ type: Boolean, default: false }) readonly onlyInput!: boolean;

    empty = false;
    loading = false;

    get selectedCustomer() {
        return customerModule.selectedCustomer;
    }

    get customers() {
        return customerModule.customerSearch;
    }

    search = '';
    keyFilter: keyof Customer = 'fullName';
    hiddenButton = false;
    typoDoc = 'surnames';
    placeholder = 'Ingrese apellidos del Contacto';
    maxLenght: number | null = null;

    selectCustomer(customer?: any) {
        this.$emit('select', customer);
        this.$emit('input', customer?._id);
        if (!this.onlyInput) {
            customerModule.selectCustomer(customer);
        }
    }

    removeCustomer() {
        this.search = this.changeDocumentNumber({
            value: this.search,
            maxlengthDocument: this.maxLenght
        });
        if (
            this.selectedCustomer &&
            this.search !== this.selectedCustomer[this.keyFilter]
        ) {
            this.selectCustomer();
        }
        this.empty = false;
    }

    querySearch(queryString: string, cb: Function) {
        this.loading = true;
        customerModule.searchCustomers().then(() => {
            const links = this.customers;
            const results = queryString
                ? links.filter(this.createFilter(queryString))
                : links;
            if (results.length < 1) {
                customerModule
                    .searchCustomers({
                        query: queryString,
                        keyFilter: this.keyFilter
                    })
                    .then((data) => {
                        cb(data);
                        this.loading = false;
                    });
            } else {
                cb(results);
                this.loading = false;
            }
        });
    }

    selectOne(queryString: string, cb: Function) {
        const links = this.customers;
        this.loading = true;
        const [result] = queryString
            ? links.filter(this.createFilter(queryString))
            : links;
        if (!result) {
            customerModule
                .searchCustomers({
                    query: queryString,
                    keyFilter: this.keyFilter
                })
                .then(([customer]) => {
                    this.loading = false;
                    cb(customer);
                    this.selectCustomer(customer);
                    if (!customer) this.empty = true;
                });
        } else {
            cb(result);
            this.selectCustomer(result);
            this.loading = false;
        }
    }

    createFilter(queryString: string) {
        return (element: any) => {
            const val = this.normalizeText(queryString);
            const item = this.normalizeText(element[this.keyFilter]);
            return item.includes(val);
        };
    }

    handleDocumentType(value: string) {
        this.maxLenght = this.changeDocumentType(value);
        if (this.maxLenght && this.maxLenght > 0) {
            this.placeholder = 'Ingrese Nro Documento';
            this.keyFilter = 'documentNumber';
        } else {
            this.placeholder = 'Ingrese apellidos del Contacto';
            this.keyFilter = 'fullName';
        }
        this.search = '';
        this.selectCustomer();
        this.empty = false;
    }

    private normalizeText(queryString: string): string {
        return queryString
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }
}
