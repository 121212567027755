
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component
export default class SelectByObject extends Vue {
    @Prop({ required: true }) readonly value!: any;
    @Prop({ required: true }) readonly items!: any[];
    @Prop({ required: true, type: String }) readonly valueKey!: string;
    @Prop({ required: true, type: String }) readonly valueString!: string;
    @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
    @Prop({ type: String }) readonly size!: string;

    get selected() {
        return this.value[this.valueKey];
    }

    set selected(value: string) {
        this.input(value);
    }

    @Emit() input(value: string) {
        return this.items.find(item => item[this.valueKey] === value);
    }
}
