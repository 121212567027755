import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Customer } from '@/interfaces/customer';
import { Filter } from '@/interfaces/filter';
import { PaginateMongo } from '@/interfaces/paginate';

@Module({ generateMutationSetters: true })
export class CustomerModule extends VuexModule {
    customerSearch: Customer[] = [];
    selectedCustomer: Customer | undefined = undefined;
    customer: Customer | null = null;
    associate: Customer | null = null;

    customerRequests: PaginateMongo<Customer> = {
        docs: [],
        totalDocs: 0
    };

    filter: Filter = {
        page: 1
    };

    @Action
    async selectCustomer(customer?: Customer) {
        this.selectedCustomer = customer;
    }

    @Action
    async show(id: string) {
        const { data } = await Axios.get<Customer>(`customers/find-by-id/${id}`);
        this.customer = data;
    }

    @Action
    async getAssociateByParentId(id: string) {
        const { data } = await Axios.get<Customer>(`customers/find-by-parent-id/${id}`);
        this.associate = data;
    }

    @Action
    async update(body: Partial<Customer>) {
        await Axios.put(`customers/${body._id}`, body);
        this.setCustomer(body);
    }

    @Action
    async updateAssociated(body: Partial<Customer>) {
        await Axios.put(`customers/${body._id}`, body);
        this.setAssociated(
            this.associate
                ? Object.assign<Customer, Partial<Customer>>(
                      this.associate,
                      body
                  )
                : (body as Customer)
        );
    }

    @Action
    async storeAssociated(body: Partial<Customer>) {
        const { data } = await Axios.post('customers', body);
        if (this.customer) {
            this.setCustomer({ child: data });
        }
        this.setAssociated(data);
    }

    @Mutation
    setCustomer(body: Partial<Customer>) {
        this.customer = this.customer
            ? Object.assign<Customer, Partial<Customer>>(this.customer, body)
            : (body as Customer);
    }

    @Mutation
    setAssociated(body: Customer) {
        this.associate = body;
    }

    @Action
    async setFilter(filter: Filter) {
        this.filter = Object.assign(this.filter, filter);
    }

    @Action
    async index() {
        const { data } = await Axios.get(
            'customers/find-by-agent',
            { params: this.filter }
        );
        this.customerRequests = data;
    }

    @Action
    remove(id: string) {
        return Axios.delete(`customers/delete-customer-by-id/${id}`);
    }

    @Action
    async getCustomersOfUser(query: any) {
        const { data } = await Axios.get('customers', {
            params: query
        });
        return data;
    }

    @Action
    async searchCustomers(
        options: { query?: string; keyFilter?: string } = {}
    ) {
        if (
            (this.customerSearch.length < 1 && !options.query) ||
            options.query
        ) {
            const { data } = await Axios.get<Customer[]>(
                'customers',
                {
                    params: {
                        [options.keyFilter as string]: options.query
                    }
                }
            );
            this.customerSearch = this.customerSearch.concat(
                data.filter(({ _id }) =>
                    this.customerSearch.every(x => x._id !== _id)
                )
            );
            return data;
        }
        return this.customerSearch;
    }

    @Action
    async updateSearchCache(body: Partial<Customer>) {
        this.customerSearch = this.customerSearch.map(customer => {
            if (customer._id === body._id) {
                return { ...customer, ...body };
            }
            return { ...customer };
        });
    }

    @Action
    async customerRegister(body: Partial<Customer>) {
        const { data } = await Axios.post('customers', body);
        const customer = {
            ...data,
            fullName: `${body.name} ${body.firstSurname} ${body.secondSurname}`
        };
        this.customerSearch = this.customerSearch.concat([customer]);
        this.selectedCustomer = customer;
    }
}
export const customerModule = new CustomerModule({ store, name: 'customer' });
