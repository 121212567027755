


































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import SelectByObject from '@/components/SelectByObject.vue';
import AutoCompleteCustomer from '@/components/AutoCompleteCustomer.vue';
import { Filter } from '@/interfaces/filter';

@Component({
    components: { SelectByObject, AutoCompleteCustomer }
})
export default class FilterTable extends Vue {
    @Prop() blocked!: string[];
    @Prop({ default: 'mini', type: String }) size!: string;
    @Prop({ default: true, type: Boolean }) showLabel!: boolean;
    @Prop({ default: 'success', type: String }) type!: boolean;
    @Prop() defaultFilter!: Filter & { label: string };

    filter: Filter & { label: string } = this.defaultFilter || {
        typeFilter: 'all',
        label: 'Todos'
    };

    options = [
        {
            typeFilter: 'all',
            label: 'Todos'
        },
        {
            typeFilter: 'dni',
            label: 'DNI'
        },
        {
            typeFilter: 'email',
            label: 'Email'
        },
        {
            typeFilter: 'surnames',
            label: 'Apellidos'
        },
        {
            typeFilter: 'customer',
            label: 'Nombres o Apellidos'
        },
        {
            typeFilter: 'status',
            filterValue: '3',
            label: 'Aprobados'
        },
        {
            typeFilter: 'status',
            filterValue: '0',
            label: 'Sin Aprobar'
        }
    ];

    get filters() {
        if (!this.blocked) return this.options;
        else
            return this.options.filter(
                ({ typeFilter }) => !this.blocked.includes(typeFilter)
            );
    }

    handleSelect(data: Filter) {
        if (['status', 'all'].includes(this.filter.typeFilter as string)) {
            this.filterData(data);
        }
    }

    selectCustomer(customer: any) {
        this.filterData({
            typeFilter: this.filter.typeFilter,
            filterValue: customer._id
        });
    }

    @Emit() filterData(data: Filter) {
        return data;
    }
}
